.loader {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 14px;
}
.loader div {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader div:nth-child(1) {
    left: 8px;
    animation: scaleUp 0.6s infinite;
}
.loader div:nth-child(2) {
    left: 8px;
    animation: slideRight 0.6s infinite;
}
.loader div:nth-child(3) {
    left: 32px;
    animation: slideRight 0.6s infinite;
}
.loader div:nth-child(4) {
    left: 56px;
    animation: slideRight 0.6s infinite;
}
.loader div:nth-child(5) {
    left: 80px;
    animation: scaleDown 0.6s infinite;
}
@keyframes scaleUp {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes scaleDown {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes slideRight {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
